import styled from "styled-components"

export const PricingLargeSection = styled.div`
  background: ${props => props.backgroundColor || "white"};
  color: ${props => props.fontColor || "black"};

  .font-color-selected {
    color: ${props => props.fontColor || "black"};
  }
`

export const PricingLargeContainer = styled.div`
  padding: 80px 15px;

  ul {
    margin-left: 2.5rem;
  }

  li {
    margin-bottom: 0;
  }

  .align-self-center {
    align-self: center;
  }
`

export const PricingLargeCol1 = styled.div`
  text-align: center;

  .pricingLargeHeader {
    padding: 0 2rem;
  }

  .pricingLargeBody {
    line-height: 18px;
    font-size: 12px;
    margin-top: 1rem;
  }

  .pricingLargeLogo {
    max-width: 250px;
  }
`
