import React, { useState } from 'react'
import BannerSectionCustom from '../containers/CustomContainers/Banner'
import FooterCustom from '../containers/CustomContainers/Footer'
import HeadermenuCustom from '../containers/CustomContainers/HeadermenuBs'
import ContactPoc from '../containers/CustomContainers/Contact'
import BlogPosts from '../containers/CustomContainers/BlogPosts'
import Products from '../containers/CustomContainers/Products'
import PricingLarge from '../containers/CustomContainers/PricingLarge'
import TextDescription from '../containers/CustomContainers/SimpleTextDescription'
import FeaturesImageVersion from '../containers/CustomContainers/FeaturesImageVersion'
import KeyFeatures from '../containers/CustomContainers/KeyFeatures'
import CookieContainer from '../containers/CustomContainers/CookieContainer'
import GlobalStyle from '../containers/CustomContainers/Common/global-styles'
import '../components/layout.css'
import Seo from '../components/seo'
import Fonts from '../containers/CustomContainers/Common/fonts'
import { graphql } from 'gatsby'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import Partners from '../containers/CustomContainers/Partners'

const PBIDataPortal = ({ data }) => {
    const AppName = 'PowerBI Data Portal'
    const SharedData = data.sharedJson
    const pbiDataPortalJson = data.pbiDataPortalJson
    const [blocking, setBlocking] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const partners = data.allPowerBiTilesPowerBiTilesPartnersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }
        return content
    })

    return (
        <BlockUi
            tag="div"
            blocking={blocking}
            message="Submiting your message"
            keepInView
        >
            <React.Fragment>
                <div>
                    <Fonts/>
                    <GlobalStyle/>
                    <HeadermenuCustom
                        data={pbiDataPortalJson.Header}
                        expanded={expanded}
                        expandToggle={newValue => setExpanded(newValue)}
                    />
                    <main onClick={() => setExpanded(false)}>
                        <Seo
                            title={pbiDataPortalJson.SEO.Title}
                            description={pbiDataPortalJson.SEO.Description}
                            favIconPath={pbiDataPortalJson.SEO.FavIconPath}
                            previewImage={pbiDataPortalJson.SEO.PreviewImage}
                            keywords={pbiDataPortalJson.SEO.Keywords}
                        />
                        <BannerSectionCustom data={pbiDataPortalJson.Banner}/>
                        <TextDescription data={pbiDataPortalJson.TextDescription}/>
                        <FeaturesImageVersion data={pbiDataPortalJson.FeaturesImage}/>

                        <KeyFeatures data={pbiDataPortalJson.KeyFeatures}/>

                        <PricingLarge data={pbiDataPortalJson.PricingLarge}/>

                        <Products
                            sharedData={SharedData.Products}
                            data={pbiDataPortalJson.Products}
                        />

                        <BlogPosts data={pbiDataPortalJson.BlogPosts}/>
                        <ContactPoc
                            sharedData={SharedData.ContactUs}
                            data={pbiDataPortalJson.ContactUs}
                            blockUIChange={newValue => setBlocking(newValue)}
                            appName={AppName}
                        />

                        {/* <Partners data={partners}/> */}
                    </main>
                    <FooterCustom data={pbiDataPortalJson.Footer}/>
                </div>
                <CookieContainer sharedData={SharedData.Cookie}/>
            </React.Fragment>
        </BlockUi>
    )
}

export const pageQuery = graphql`
    query dataportalPageQuery {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/(PBIDataPortal/FAQS)/" } }
            sort: { order: ASC, fields: [frontmatter___category, frontmatter___path] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        category
                    }
                    html
                }
            }
        }
        allPowerBiTilesPowerBiTilesPartnersListItem(
            sort: {order: ASC, fields: [data___fields___ColumnOrder]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
        sharedJson {
            Products {
                Items {
                    ProductsCardIcon
                    ProductsCardHeading
                    ProductsCardPara
                    ProductRoute
                }
            }
            ContactUs {
                SectionHeading
                SectionSubHeading
                ContactRight {
                    ContactRightHeading
                    ContactRightPara
                }
            }
            Cookie {
                CookieName
                CookieExpireDays
                SaveCookieOnDecline
                OkButtonMessage
                CookieMessage
            }
        }
        pbiDataPortalJson {
            SEO {
                Title
                Description
                FavIconPath
                PreviewImage
                Keywords
            }
            Header {
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems {
                    Menu
                    Href
                    LocalLink
                }
            }
            Banner {
                BannerHeading
                BannerDescription
                WatchVideoAnchor
                BtnText {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                BtnText2 {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                PathToImage
            }
            TextDescription {
                BackgroundGrey
                Heading
                Border
                Description
            }
            FeaturesImage {
                Features {
                    Button {
                        Href
                        Name
                    }
                    FeaturesDescription
                    FeaturesHeading
                    FeaturesVideoPositionLeft
                    Image1
                    Image2
                }
                DelayConstant
                FeaturesHeading
                FeaturesSubHeading
            }
            KeyFeatures {
                BackgroundGrey
                DelayConstant
                KeyFeaturesHeading
                KeyFeaturesPara
                KeyFeaturesItems {
                    KeyFeaturesTitle
                    KeyFeaturesDescription
                }
            }
            PricingLarge {
                BackgroundColor
                FontColor
                PricingLargeCol1 {
                    Header
                    ButtonLabel
                    ButtonHref
                    Body
                    Logo
                }
                PricingLargeCol2 {
                    Header
                    Body
                }
            }
            Products {
                BackgroundGrey
                DelayConstant
                ProductsLeftHeading
                ProductsLeftPara
                ProductsLeftBtn {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
            }
            BlogPosts {
                BackgroundGrey
                SectionHeading

                BlogImg1
                BlogName1
                BlogDesc1
                DetailsText1
                Blog1Button {
                    Name
                    Href
                }

                BlogImg2
                BlogName2
                BlogDesc2
                DetailsText2
                Blog2Button {
                    Name
                    Href
                }

                BlogImg3
                BlogName3
                BlogDesc3
                DetailsText3
                Blog3Button {
                    Name
                    Href
                }
            }
            Footer {
                FooterLogo
                Items
                FooterCol2 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                        LocalLink
                    }
                }
                FooterCol3 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                    }
                }
                FooterCol4 {
                    FooterTitle
                    FooterAddWrapper {
                        FooterAddImg
                        FooterAddText
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                SocialLinks {
                    FacebookLink
                    TwitterLink
                    LinkedinLink
                    InstagramLink
                }
            }
            ContactUs {
                BackgroundGrey
            }
        }
    }
`

export default PBIDataPortal
