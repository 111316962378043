import React from "react"
import {
  OurProductSection,
  ProductsBtn,
  ProductsHeading,
  ProductsHeadingWrapper,
  ProductsInner,
  ProductsLeft,
  ProductsLeftImgFirst,
  ProductsLeftImgSecond,
  ProductsRight,
  ProductsRightImgFirst,
  ProductsRightImgSecond,
  ProductsRow,
  ProductsRowReverse,
} from "./FeatureImageVersion.style"
import { Col, Container, Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Fade from "react-reveal/Fade"

const FeaturesImageVersion = ({ data }) => {
  const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF"

  return (
    <OurProductSection
      id="featuresImageVersionContainer"
      style={{ background: color }}
    >
      <Container>
        <Row>
          <Col md="12">
            <ProductsHeadingWrapper>
              <Fade bottom delay={1 * data.DelayConstant}>
                <ProductsHeading>{data.FeaturesHeading}</ProductsHeading>
              </Fade>
              <Fade bottom delay={2 * data.DelayConstant}>
                <h6>{data.FeaturesSubHeading}</h6>
              </Fade>
            </ProductsHeadingWrapper>
          </Col>
        </Row>

        <ProductsInner>
          {data.Features.map((item, idx) => {
            return item.FeaturesVideoPositionLeft ? (
              <ProductsRow>
                <Col lg="6">
                  <Fade left delay={1 * data.DelayConstant}>
                    <ProductsRight style={{ textAlign: "left" }}>
                      <ProductsLeftImgFirst src={item.Image1} alt="" />
                      <ProductsLeftImgSecond src={item.Image2} alt="" />
                    </ProductsRight>
                  </Fade>
                </Col>
                <Col lg="6">
                  <ProductsLeft>
                    <Fade right delay={1 * data.DelayConstant}>
                      <h3>{item.FeaturesHeading}</h3>
                    </Fade>
                    <Fade right delay={2 * data.DelayConstant}>
                      <p>{item.FeaturesDescription}</p>
                    </Fade>
                    <Fade right delay={3 * data.DelayConstant}>
                      <ProductsBtn href={item.Button.Href} target="_blank">
                        {item.Button.Name}
                      </ProductsBtn>
                    </Fade>
                  </ProductsLeft>
                </Col>
              </ProductsRow>
            ) : (
              <ProductsRowReverse padddingBottom={"80px"}>
                <Col lg="6">
                  <Fade right delay={1 * data.DelayConstant}>
                    <ProductsRight>
                      <ProductsRightImgFirst src={item.Image1} alt="" />
                      <ProductsRightImgSecond src={item.Image2} alt="" />
                    </ProductsRight>
                  </Fade>
                </Col>

                <Col lg="6">
                  <ProductsLeft>
                    <Fade left delay={1 * data.DelayConstant}>
                      <h3>{item.FeaturesHeading}</h3>
                    </Fade>
                    <Fade left delay={1 * data.DelayConstant}>
                      <p>{item.FeaturesDescription}</p>
                    </Fade>
                    <Fade left delay={1 * data.DelayConstant}>
                      <ProductsBtn href={item.Button.Href} target="_blank">
                        {item.Button.Name}
                      </ProductsBtn>
                    </Fade>
                  </ProductsLeft>
                </Col>
              </ProductsRowReverse>
            )
          })}
        </ProductsInner>
      </Container>
    </OurProductSection>
  )
}
export default FeaturesImageVersion
