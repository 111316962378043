import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { PricingLargeSection, PricingLargeContainer, PricingLargeCol1 } from "./pricingLarge.style"
import { Commonbtn } from "../Common/common.style"

const PricingLarge = ({data}) => {

  return (
    <PricingLargeSection backgroundColor={data.BackgroundColor} fontColor={data.FontColor} id="pricingLargeContainer">
      <Container>
        <PricingLargeContainer>
          <Row>
            <Col className="align-self-center" sm="12" md="6">
              <PricingLargeCol1>
                <h3 className="pricingLargeHeader font-color-selected">
                  {data.PricingLargeCol1.Header}
                </h3>
                <Commonbtn href={data.PricingLargeCol1.ButtonHref} target="_blank">
                  {
                    data.PricingLargeCol1
                      .ButtonLabel
                  }
                </Commonbtn>
                <p className="pricingLargeBody font-color-selected">
                  {data.PricingLargeCol1.Body}
                </p>
                <img className="pricingLargeLogo" src={data.PricingLargeCol1.Logo} alt=""></img>
              </PricingLargeCol1>
            </Col>
            <Col className="align-self-center"  sm="12" md="6">
              <p className="font-color-selected">
                {data.PricingLargeCol2.Header}
              </p>
              <ul>
                {data.PricingLargeCol2.Body.map(
                  item => (
                    <li className="font-color-selected">
                      <p className="font-color-selected">{item}</p>
                    </li>
                  )
                )}
              </ul>
            </Col>
          </Row>
        </PricingLargeContainer>
      </Container>
    </PricingLargeSection>
  )
}

export default PricingLarge
